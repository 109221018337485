<template>
  <div class="static-page landing-static"  :class="{ 'rtl': locale === 'ar' }">
    <AppHeader appName="main" :locale="locale"/>
    <main>
      <section class="faq-page">
        <div class="container-medium">
          <h1 class="main-title">{{$t('faq_freq_asked')}}</h1>
          <BaseInput
            ref="input"
            :placeholder="$t('faq_search_your_questions')"
            inputStyle="big"
            class="faq-page__input"
            @click="onInputClick"
            v-model="searchValue"/>
          <h2>{{$t('faq_choose_category')}}</h2>
          <div class="faq-page__category">
            <div class="faq-page__category-item car" @click="selectCategory('car')">
              <p>{{$t('faq_car')}}</p>
            </div>
            <div class="faq-page__category-item boat" @click="selectCategory('boat')">
              <p>{{$t('faq_boat')}}</p>
            </div>
            <div class="faq-page__category-item travel" @click="selectCategory('travel')">
              <p>{{$t('faq_travel')}}</p>
            </div>
            <div class="faq-page__category-item moto" @click="selectCategory('moto')">
              <p>{{$t('faq_moto')}}</p>
            </div>
            <div class="faq-page__category-item pab" @click="selectCategory('pab')">
              <p>{{$t('faq_pab')}}</p>
            </div>
            <div class="faq-page__category-item home" @click="selectCategory('home')">
              <p>{{$t('faq_home')}}</p>
            </div>
            <div class="faq-page__category-item claims" @click="selectCategory('claims')">
              <p>{{$t('faq_claim')}}</p>
            </div>
          </div>
          <LandingFaqs
            v-if="!error"
            faqHeaderKey="question"
            faqContentKey="answer"
            :title="currentCategoryTitle"
            :disableTitle="categorySelected || !currentCategoryTitle"
            :faqList="filtredFaqs"/>
        </div>
      </section>
    </main>
    <AppFooter :locale="locale"/>
  </div>
</template>

<script>
import { AppHeader, AppFooter, BaseInput, LandingFaqs } from 'qic-components'
import { mapActions } from 'vuex'
export default {
  name: 'FaqPage',
  components: {
    AppHeader,
    AppFooter,
    BaseInput,
    LandingFaqs
  },
  props: {
    value: String
  },
  data () {
    return {
      error: false,
      placeholder: ' ',
      loadingFaqs: false,
      faqs: null,
      currentFilter: 'general',
      categoryCodesOrder: [],
      categorySelected: false,
      searchValue: ''
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    isArabic () {
      return this.locale === 'ar'
    },
    filtredFaqs () {
      if (!this.faqs) return []
      if (this.currentFilter === 'all') {
        return []
      } else {
        if (!this.searchValue) return this.faqs[this.currentFilter].list
        return this.filtredAllFaqsMap[this.currentFilter].list
      }
    },
    currentCategoryTitle () {
      if (!this.faqs) return ''
      return this.faqs[this.currentFilter] ? this.faqs[this.currentFilter].title : ''
    }
  },
  methods: {
    ...mapActions([
      'getFaqList'
    ]),
    selectCategory (category) {
      this.$router.push({ name: 'FaqCategory', params: { category } })
    },
    onInputClick () {
      this.$router.push({ name: 'FaqSearch' })
    }
  },
  mounted () {
    this.loadingFaqs = true
    this.getFaqList(this.locale)
      .then((faqsInfo) => {
        if (!faqsInfo || !(typeof faqsInfo.faqs === 'object')) {
          this.error = true
          return
        }

        this.faqs = faqsInfo.faqs
        this.categoryCodesOrder = faqsInfo.categoryCodes
      })
      .finally(() => {
        this.loadingFaqs = false
      })
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>
